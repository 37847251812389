import qs from "qs";

export const getStrapiUrl = (url: string, query?: NonNullable<unknown>) => {
  const stringQuery = qs.stringify(
    {
      ...query,
      pagination: {
        page: 1,
        pageSize: 300,
      },
    },
    { encodeValuesOnly: true },
  );
  return `${url}?${stringQuery}`;
};
